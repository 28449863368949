// @ts-ignore
import { defaults } from './defaultConfigs';

export const Localization = {
  ...defaults,
  assignedCs: 'Assigned CS',
  csId: 'CS ID',
  currencySymbol: '₹',
  currencyAbbrev: 'INR',
  branch: 'Teams',
  accountBranch: 'Branch',
  branches: 'Branches',
  collectionSpecialist: 'Collection Specialists',
  principalPayment: 'Outstanding Payment*',
  showPenaltyPayment: true,
  penaltyPayment: 'Penal Payment*',
  csLeaderBoard: 'CS Leaderboard',
  branchLeaderBoard: 'Branch Leaderboard',
  collectionsBarChartTitle: 'Field Collections',
  accountsBarChartTitle: 'Field Accounts',
  collectionSpecialistPerformanceTitle: 'Collection Specialists Performance',
  branchListingHeader: 'Teams',
  branchListingBreadcrumb: 'Teams',
  csListingHeader: 'Collection Specialists',
  csListingBreadcrumb: 'Collection Specialists',
  csDetailsBreadcrumb: 'Specialist',
  logoFilename: 'Logo_HaritaAdvantage.png',
  clientName: 'Harita',
  navbarBackgroundColor: '#34276E',
  showManuals: true,
  showAIManuals: false,
  showAPK: false,
  csManualLink: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/artha/manuals/Wheeltek_CS_UserGuide.pdf',
  cashierManualLink: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/artha/manuals/Wheeltek_Cashier_UserGuide.pdf',
  managerManualLink: 'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/artha/manuals/Harita_Manager_UserManual.pdf',
  aiManualLink: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/artha/manuals/Wheeltek_AI_Itinerary.pdf',
  apkLink: process.env.APK_URL || '',
  eulaTextUrl: 'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/eula/EULA_harita_mobile.txt',
  eulaVersionUrl: 'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/eula/EULA_harita_mobile.json',
  privacyStatementUrl:
    'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/privacy-statement/mobile_application_privacy_statement.txt',
  privacyStatementVersion:
    'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/privacy-statement/mobile_application_privacy_statement.json',
  zipcodeLength: 6,
  zipcodeValidationMsg: 'India zipcode should have 6 characters. Ex: 744202, 683544',
  country: 'India',
  countryAbbrev: 'IN',
  transactionListingHeader: 'Transaction Listing',
  transactionListingBreadcrumb: 'Transaction Listing',
  activateTransactionListing: true,
  activateCreditDebitMemo: true,
  activatePPD: false,
  currencyLocale: 'en-IN',
  hiddenPenaltyField: true,
  csReportDownload: false,
  cashierReportDownload: true,
  appId: '1:834347563222:web:67cd11a9f854403c9ab177',
  measurementId: 'G-9Q4XLVE5HP',
  showTranslation: false,
  recoveredAccountsLabel: 'RECOVERED ACCOUNTS',
  noOfPaymentsLabel: 'NO. OF PAYMENTS',
  showCashierPage: false,
  displayClientCode: true,
  metricsToDisplay: ['mtd-collection-efficiency', 'account-recovery-rate', 'average-visits-per-day'],
  cspStatsToHide: ['total_skipped_visits', 'itinerary_non_compliance', 'visit_per_day'],
  showSummaryReportDownload: true,
  collectionSpecialistItinerary: 'Collection Specialist Itinerary',
};
